@font-face {
  font-family: 'BLOKK';
  src: url('/assets/fonts/BLOKKNeue-Regular.eot');
  src: url('/assets/fonts/BLOKKNeue-Regular.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/BLOKKNeue-Regular.woff2') format('woff2'),
       url('/assets/fonts/BLOKKNeue-Regular.woff') format('woff'),
       url('/assets/fonts/BLOKKNeue-Regular.otf') format('opentype'),
       url('/assets/fonts/BLOKKNeue-Regular.ttf') format('truetype'),
       url('/assets/fonts/BLOKKNeue-Regular.svg#BLOKKRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'BLOKK';
    src: url('/assets/fonts/BLOKKNeue-Regular.svg') format('svg');
  }
}
