/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'material-mdc-mixins' as material-mdc-mixins;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Include legacy-core to stall the mdc migration
@include mat.legacy-core();

// Create palettes for the theme object.
$palette-primary: mat.define-palette(mat.$cyan-palette);
$palette-accent:  mat.define-palette(mat.$light-blue-palette, A200, A100, A400);
$palette-warn:    mat.define-palette(mat.$red-palette);

$candy-app-color-config: (
  primary: $palette-primary,
  accent: $palette-accent,
  warn: $palette-warn
);

// Include legacy component themes
@include material-mdc-mixins.all-component-themes($candy-app-color-config);
@include mat.all-legacy-component-typographies();