/* You can add global styles to this file, and also import other style files */
@import 'projects/core/src/lib/styles/material';
@import 'projects/core/src/lib/styles/sondermind';

@import './assets/stylesheets/_blokkfont';
@import 'member-variables';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';
@import './assets/stylesheets/sonder-modal';

// Imports from iris-design-system-styles package
@import 'typography.scss';
@import 'link.scss';
@import '1-tokens/-index'; // design tokens
@include mat-overrides-theme();

/* Fix for spinner animations in Angular Material */
.mat-spinner,
.mat-mdc-progress-spinner {
  display: block !important;
  
  /* Ensure colors are properly applied */
  &.mat-primary {
    --mdc-circular-progress-active-indicator-color: #004455 !important;
  }

  /* Ensure visibility and proper sizing */
  .mdc-circular-progress {
    width: 100% !important;
    height: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

/* Force mat-spinner to show animation */
.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__indeterminate-container,
.mat-spinner .mdc-circular-progress__indeterminate-container {
  animation: mdc-circular-progress-rotate 2s linear infinite !important;
}

.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__spinner-layer,
.mat-spinner .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both !important;
}

.mat-mdc-progress-spinner.mat-mdc-progress-spinner-indeterminate .mdc-circular-progress__indeterminate-circle-graphic,
.mat-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  animation: mdc-circular-progress-spinner-stroke-rotate 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite !important;
  stroke-dasharray: 80, 200 !important;
  stroke-dashoffset: 0px !important;
}

@keyframes mdc-circular-progress-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes mdc-circular-progress-spinner-stroke-rotate {
  0% {
    transform: rotate(0);
  }
  12.5% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(540deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(810deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.sondermind-design-system {
  @include install-sondermind-theme();
}

html {
  font-size: $font-size-base !important; // To override scaffolding.less which is set to 10px
}

.cdk-global-scrollblock {
  overflow-y: hidden;
  position: static;
}

.image-upload-modal .mat-dialog-container {
  padding: 0;
  max-height: 688px;
  overflow: hidden;
}

// I don't see a better way to do this with media queries since there's no template
// To use one in, these are just used as a panelClass for mat-dialog
.image-upload-modal-mobile .mat-dialog-container {

    //TOP TWO ONLY FOR MOBILE
    position: absolute;
    top: 0;
    
    padding: 0;
    max-height: 688px;
    overflow: hidden;

}

.tag {
  padding: 4px 5px 2px;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  cursor: default;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  &.tag-with-bottom-margin {
    margin-bottom: 5px;
  }

  &.tag-warn {
    background-color: #b93646;
    color: #fff;
  }
}

.label-sm {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  opacity: 0.5;
  font-style: normal;
  text-decoration-line: underline;
}