@use 'components/layers' as layers;

@import '_member-variables';

// ipad and smaller
.nav-side-menu {
  height: 100vh;
  position: fixed;
  @include layers.navigation();
  width:270px;
}

sondermind-profile-picture {
  display: block;
  margin: 16px auto;
}

.logo-container {
  max-width: 192px;
  margin: auto;
}

@include iris-media(">1024px") {
  .nav-side-menu {
    .mobile-header {
      display: none !important;
    }
  }
}

@include iris-media("<=1024px") {
  .nav-side-menu {
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }

  .expand-collapse-icon{
    display: none !important;
  }

  .dashboard-content {
    padding-top: 67px !important;
  }

  .menu-list > ul {
    margin-bottom: 0px !important;
  }
}
