@import 'projects/core/src/lib/styles/_common';
@import 'design-tokens';

@import '_sidebar';
@import '_errorlist';

.capitalize {
  text-transform:capitalize;
}
.no-border {
  border:none;
}

.nav-tabs > li {
  font-size: 13px;
}

.nav-tabs.child li {
 font-size: 11px;
 margin-right:8px;
}

.nav-tabs.child-nav {
  margin: 10px 0;
}

.absolute_date_picker {
  .ui-calendar {
	display:block;
  }
  .ui-datepicker {
    position: absolute;
    top:40px;
  }
}

.superbill_errors li {
  font-size: 14px;
  a {
    text-decoration: underline;
  }
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover,
.nav-tabs > li > a:hover {
  cursor:pointer;
  color: #000 !important;
  background-color: transparent !important;
  text-decoration: none !important;
  border-radius: 0px !important;
  border-bottom-width: 4px !important;
  border-bottom-style: solid  !important;
  border-bottom-color: #3BA7AE  !important;
}

.nav-tabs > .active > a {
  font-weight: bold !important;
}

.sonder_text_color {
  color: #3BA7AE  !important;
}

.customTable {
  .ui-widget {
    font-family: inherit !important;
  }

  .ui-datatable thead th, .ui-datatable tbody td, .ui-datatable tfoot td, .ui-datatable tfoot th {
    border-width:0px !important;
    vertical-align:middle;
    padding: 6px 0;
  }

  .ui-datatable th.ui-state-active {
    color: #3BA7AE;
  }

  .ui-datatable .ui-sortable-column-icon {
    margin-left: 0.6em;
  }

  td.ui-datatable-emptymessage {
      text-align: center !important;
  }

  .ui-datatable thead tr {
    border-top-width:0px !important;
    border-bottom-width: 1px !important;
  }

  .ui-datatable thead th {
    font-weight: bold !important;
  }

  .ui-paginator {
    text-align: center !important;
    margin-top:10px !important;
  }

  .ui-widget-header {
    margin:10px 0;
    padding:0;
    border-bottom: 0 none;
    border-top: 0 none;
  }

  .filterBtn {
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right:-10px;
  }

  .ui-dropdown {
    .ui-dropdown-label {
      float:none;
      overflow:visible;
    }
  }

  .filterInput {
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 20%;
    margin-right:-2px;
    font-family: inherit;
  }

  div, span, tr, td, th, table, tbody, thead, tfoot {
    background: transparent !important;
    text-align: left !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  p-paginator {
    .ui-paginator {
      div, span, tr, td, th, table, tbody, thead, tfoot {
        border-left-width: 1px !important;
        border-right-width: 1px !important;
      }
    }
  }

  .ui-datatable tbody td, .ui-datatable tbody tr {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom: 0 !important;
  }

  table {
    table-layout: inherit;
  }

  .btn-sonder-default {
    color: #fff;
  }
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover,
.nav-tabs > li > a {
  color: #3BA7AE !important;
}

.nav-tabs > li > a {
  color: #000 !important;
  border: 0px  !important;
  border-radius: 0px  !important;
}

.table td, .table th {
  padding: 8px 0 !important;
}

.table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover,
.table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > thead > tr > td.active, .table > thead > tr > th.active, tr.active > td {
  background-color: #e0e0e0 !important;
}

.statusSelection {
  margin-top:-5px;
}

.auto-width {
  width:auto !important;
}

.inline {
  display:inline-block !important;
}

.margin-bottom-10 {
  margin-bottom:10px;
}

.margin-bottom-5 {
  margin-bottom:5px;
}

.margin-top-5 {
  margin-top:5px;
}

.margin-top-10 {
  margin-top:10px;
}

.sectionHeading {
  border-bottom:1px solid lightgrey;
  font-size:13px;
  font-weight:600;
  margin-bottom:10px;
  padding-bottom:5px;
}

.no-border {
  border: none !important;
}

.top-align {
  vertical-align: top;
}

.text-bottom {
  vertical-align: text-bottom !important;
}

.bottom-align {
  vertical-align: bottom !important;
}

.middle-align {
  vertical-align: middle !important;
}

.sub-align {
  vertical-align: sub !important;
}

.overFlowHidden {
  overflow-x: hidden;
  overflow-y: auto;
}

.margin-left-15 {
  margin-left:15px;
}

.margin-left-10 {
  margin-left:10px;
}

.margin-right-10 {
  margin-right:10px;
}

.cursor_not_allowed {
  cursor: not-allowed !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.cursor_default {
  cursor: default !important;
}

.superBills {
  .input-sm {
    box-shadow: none;
  }

  .panel {
    .panel-heading {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}

p-inputmask, p-calendar {
  &.ng-invalid.ng-dirty:not(form) {
    border-left:none !important;

    input {
       border-left: 5px solid #a94442 !important;
    }
  }
}

.input-date-picker {
  box-shadow: none;
  border-radius: 0px;
}

.text-red {
  color: red;
}

.text-orange {
  color: #ff8c00;
}

.tableWithData {
  .text-right {
    text-align: right !important;
  }

  thead {
    display: none !important;
  }
}

.sortableTable {
  tbody {
    display:none !important;
  }
}

.no-box-shadow {
  box-shadow: none;
}

.ng-progress {
  z-index: 9999 !important;
}

ul.same-line-text {
  padding: 0px;

  li {
    list-style: none;

    div {
      display: table-cell; vertical-align: middle;
    }

    label {
      margin-left:5px;
    }
  }
}

.ui-datatable-loading-content {
  margin-top: 15%;
}

.custom_hr {
  border-color: lightgrey;
  float: left;
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
}

ng-progress.no-bar {
  .ng-bar {
    display: none;
  }
}

.padding-top-5 {
  padding-top: 5px !important;
}

.modal-document-content {
  max-height: 350px;
  overflow: auto;
}

.mat-flat-button.mat-success, .mat-raised-button.mat-success, .mat-fab.mat-success, .mat-mini-fab.mat-success {
  background-color: #6CC070;
  color: #ffff;
}

.confirmation-with-icon {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  color: #f44336;

  .confirmation-icon {
    place-content: center space-around;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    max-width: 10%;
  }
  
  .confirmation-message {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 90%;
  }
}

.no-margin {
  margin: 0px !important;
}

.marginLeft10 {
  margin-left: 10px
}
