@import '1-tokens/-index'; // design tokens
@import '2-tools/_tools.helpers'; // helper functions & mixins
@import '2-tools/_tools.breakpoints'; // breakpoint specific functions & mixins

@import 'member-variables';

html {
  font-size: $font-size-base; // To override scafolding.less which is set to 10px;
}

.test {
  color:white
}
.cursor_pointer{
  cursor: pointer;
}
.light_grey {
  color: #d3d3d3 !important;
}

//* {
//  margin: 0;
//  padding: 0;
//}

// There is a chrome bug on select boxes. We will turn this off for now.

select {transition: none !important;  }

body {
  font-family: "museo-sans";
  font-style: normal;
  font-weight: normal;
  color: $text_color;
  font-size: 12px;
  padding-right: 0 !important;
}
.form-control {
  border-radius: 0px;
}

.dashboard-content {
  &:not(.dashboard-content-initializing) {
    transition: transform 0.3s ease-in-out 0s, padding 0.3s ease-in-out 0s;
  }

  &.dashboard-appscreen {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: var(--vh100, 100vh);
  }

  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 270px;

  @include iris-media("<=1024px") {
    padding: 0;
  }

  .page-container {
    min-height: 100vh;
    padding: 47px 36px 36px;
    position: relative;

    @include iris-media("<=1024px") {
      padding: 8px 12px;
    }
  }
}

label {
  font-weight: normal !important;
}
.nav-pills>li:first-of-type {
  margin-left: 0px;
}
.nav-pills>li:last-of-type {
  margin-right: 0px;
}

.no-right-padding {
  padding-right: 0px;
}

.no-left-padding {
  padding-left: 0px;
}
.form-control {
  border: 1px solid #c0c0c0;
}

.form-control.input-file {
  border: none;
  box-shadow: none;
  padding: 6px 0px;
}

.nav-pills>li+li {
  padding-left: 25px;
  padding-right: 25px;
}

.nav>li>a {
  padding: 5px 5px;
}

.nav-pills>li>a {
  //border-radius: 50px;
  background: #E6E6E6;
  color: #666666;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover  {
  background: #60ADB3;
  color: #ffffff;
}

.btn-sonder-default {
  color: #FFFFFF;
  background-color: #3BA7AE;
  border-color: #3BA7AE;
}

.btn-sonder-default:hover,
.btn-sonder-default:focus,
.btn-sonder-default:active,
.btn-sonder-default.active,
.open .dropdown-toggle.btn-sonder-default {
  color: #FFFFFF;
  background-color: #439DA3;
  border-color: #3BA7AE;
}

.btn-sonder-default:active,
.btn-sonder-default.active,
.open .dropdown-toggle.btn-sonder-default {
  background-image: none;
}

.btn-sonder-default:visited {
  color: white;
}
.btn-sonder-default:link {
  color: white;
}

.btn-sonder-default.disabled,
.btn-sonder-default[disabled],
fieldset[disabled] .btn-sonder-default,
.btn-sonder-default.disabled:hover,
.btn-sonder-default[disabled]:hover,
fieldset[disabled] .btn-sonder-default:hover,
.btn-sonder-default.disabled:focus,
.btn-sonder-default[disabled]:focus,
fieldset[disabled] .btn-sonder-default:focus,
.btn-sonder-default.disabled:active,
.btn-sonder-default[disabled]:active,
fieldset[disabled] .btn-sonder-default:active,
.btn-sonder-default.disabled.active,
.btn-sonder-default[disabled].active,
fieldset[disabled] .btn-sonder-default.active {
  background-color: #3BA7AE;
  border-color: #3BA7AE;
}

.btn-sonder-default .badge {
  color: #3BA7AE;
  background-color: #FFFFFF;
}


.col-3-check-list {
  width: 33%;
  float: left;
  margin-bottom: 10px;
  input[type='checkbox'] {
    position: relative;
    top: 5px;
    -webkit-appearance:none;
    width:20px;
    height:20px;
    background:white;
    border-radius:0px;
    border:1px solid #555;
  }
  input[type='checkbox']:checked {
    background: #3BA7AE;
  }
}

.service-info-header {
  background: #F5F5F5;
  padding: 10px;
}

.panel {
  border-radius: 0px;
}

.modal-content {
  border-radius: 0px;
}

.modal-document {
    .modal-body {
        max-height: 75vh;
        overflow-y: scroll;
    }
}

.input-auto-complete {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.ui-calendar .ui-datepicker {
  width: auto;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next{
  top: 0.190em;
  span {
    left: 32%;
    top: 51%;
  }
}

.mat-step-icon .mat-icon {
  margin: 4px;
}

.inline-material-spinner {
  display: inline !important;
  margin-right: 15px;
  vertical-align: top !important;
  opacity: 0.4 !important;
  margin-left: 5px;
}

.mat-mdc-option-warning {
  color: #f44336;
  mat-icon {
    margin-right: -5px !important;
    vertical-align: sub !important;
  }
}
