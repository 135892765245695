.cropper-container.cropper-bg {
  width: 100% !important;
}

.cropper-crop-box, .cropper-view-box {
  border-radius: 16px;
  z-index: 4;
}

.cropper-view-box {
  box-shadow: 0 0 0 3px #FFFFFF;
  outline: 0;
}

.cropper-wrap-box {
  background-color: black;
}

.cropper-canvas {
  z-index: 2;
  opacity: 0.5;
  background-color: white;
}

.cropper-drag-box {
  z-index: 2;
}

.image-background {
  .cropper-line {
    opacity: 0;
  }
}

.cropper-point {
  opacity: 1 !important;
}

.cropper-dashed {
  opacity: 1;
  border: 1px solid #ffffff;

  &.dashed-h {
    left: 2.5%;
    width: 94.5%;
  }

  &.dashed-v {
    top: 2.5%;
    height: 94.5%;
  }
}
