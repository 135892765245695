@import '1-tokens/-index'; // design tokens

$mat-chip-padding: 3px 6px;

.mat-chip:not(.mat-basic-chip) {
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  padding: $mat-chip-padding;
  border-radius: $border-radius-05;
  align-items: center;
  cursor: default;
}

.mat-basic-chip-label {
  display: inline-flex;
  color: $color-neutral-white;
  padding: $mat-chip-padding;
  border-radius: 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 75% !important;
  font-weight: $font-weight-bold !important;
}

.noPad {
  padding: 0px !important;
}

.pad2 .mat-header-row {
  padding: 0px 2px !important;
}

.pad2 {
  padding: 0px 2px !important;
}

.pad3 .mat-header-row, .mat-row {
  padding: $spacing-00 $spacing-03 $spacing-00 2px !important;
}

.pad3, .mat-header-row {
  padding: $spacing-00 $spacing-03 $spacing-00 2px !important;
}

.centerMatCardTitle {
  align-items: center !important;
}

// Reduce the width of material tabs in my profile page
.matTabSmall .mat-tab-link {
  padding: 0px 10px !important; min-width: 120px !important;
}

.button-row {
  button, a {
    margin: 3px !important;
  }
  save-button-material {
    margin-left: 0px !important;
  }
}

.mat-icon.small {
  font-size: 17px;
}

mat-cell, mat-footer-cell, mat-header-cell {
  &:first-child {
    padding-left: 0px !important;
  }
  &:last-child {
    padding-right: 0px !important;
  }
}

save-button-material {
  margin-left: 10px;
}

#profile {
  li {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

sondermind-profile-picture {
  .profile-image:not(.square) {
    border: 1px solid $color-brand-1-base;
  }
}
