.error-list {
  ul {
    list-style: none;
  }
}

.help-error-block{
  color: red !important;
}

.ng-invalid.ng-dirty:not(form)  {
  /*border-left: 5px solid #a94442 ;*/
}



